import React, { Component } from 'react'
import { 
Box,
Grid,
Stepper,
Step,
StepLabel,
CircularProgress,
} from '@material-ui/core'

import CustomGoogleMap from '../../components/GoogleMap/CustomGoogleMap.jsx';
import logo from '../../assets/img/logo_black.png';
import apiUtil from "../../api/apiUtil.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import CustomListItem from '../../components/List/CustomListItem.jsx';
import CustomStepper from '../../components/Stepper/CustomStepper.jsx';
import CustomGuideStepper from '../../components/Stepper/CustomGuideStepper.jsx';

export class trackMe extends Component {

    constructor(props) {
        super(props)
    
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let number = params.get('number');
        this.state = {
             token: number ? number: '',
             isLoading: false,
             data: null,
             openDriver: true,
             openRecepient: true,
             openStep: true,
             openItem: true,
             activeStep: 0,
        }
    }
    componentDidMount() {
        this.callTrackApi()
    }

    callTrackApi = (callback = null) => {

        let row = {
            item_tracking_number: this.state.token
        }
        let accessToken = null
        this.setState({
            isLoading: true
          }, () => {
            ldsApi.create('track/my/order', row, accessToken)
              .then((result) => {
                apiUtil.parseResult(result, (data) => {
                  this.setState({
                    isLoading: false,
                    data: data.result,
                  }, () => {
                    if(callback){
                      callback(data);
                    }
                    // this.getActivStep(data.result.job_steps)
                  });
                }, (error, type) => {
                  this.setState({
                    isLoading: false,
                    error: error
                  }, () => {
                    if(callback){
                      callback(null);
                    }
                  });
                });
            });
          });
    }

    errorMessage = () => {
        return <Grid container
        justifyContent='center'
        alignItems='center'
        >

            <Grid item xs={12} >
                <Box mt={5} mb={2}>
                    <div className="logo"><img src={logo} alt="Tracker logo"  height="50px"/></div>
                </Box>
                
            </Grid>
            {this.state.error && this.state.error == 'Link Expired!' ? <Grid item xs={12}>
                <Box className='error-p'>
                 <h2>Token number expired.</h2>
                 <p>The token number for this order is no longer available and you cannot track the driver anymore.</p>
                </Box>
            </Grid>: <Grid item xs={12}>
                <Box className='error-p'>
                 <h2>Oops, there is a problem with the token number.</h2>
                 <p>The token number you used is invalid. Please try again.</p>
                </Box>
            </Grid> }
            
            
        </Grid>
    }
    getJobSteps = () => {

        let job_steps = this.state.data ? this.state.data.job_steps : [] ;

        let steps = [];
        if(job_steps.length > 0) {
            job_steps = job_steps.sort(this.compareFn)
            job_steps.forEach(st => {
                const step = {
                    stage: st.job_step_status.name.replace(" ", "_"),
                    icon: st.order_sequence,
                    label: <Box><Grid container xs ={12}>
                        <Grid item xs={12}><Box>{st.job_step_name}</Box></Grid>
                        <Grid item xs={12}><Box className={st.job_step_status.name.replace(" ", "_")}>{st.job_step_status.name}</Box></Grid>
                        <Grid item xs={12}><Box>{st.location}</Box></Grid>
                        </Grid></Box>,
                  }
                steps.push(step)
            });
            
        }

        return steps
    }

    compareFn = (a, b)=>{
        if (a.order_sequence < b.order_sequence)
            return -1;
        if (a.order_sequence > b.order_sequence)
            return 1;
        return 0;
    }

    // getActivStep = (orderSteps) =>{
    //     let job_steps = orderSteps ;
    //     if (job_steps.length > 0) {
    //        job_steps.filter(step => {
    //           if(step.job_step_status_id === 2) {
    //               this.setState({
    //                   activeStep: step.order_sequence - 1,
    //               })
    //               return
    //           }
    //       })
    //     }
    // }

    trackOrder = () => {
        return <Box>
            <Grid container>
                <Grid item xs={3}>
                    <Box style={{backgroundColor: 'rgb(136 136 136 / 16%)', height:"100vh"}}>
                        <Box className='left-panel-track'>
                            <Grid container xs={12} className='panel_row order_row'>
                                <Grid item xs={12} ><Box>ORDER NUMBER</Box></Grid>
                                <Grid item xs={12} className='order_num'><Box>{this.state.data ? this.state.data.order_number: ''}</Box></Grid>
                            </Grid>
                            <Grid container xs={12} className='panel_data'>
                                <Grid item xs={12} className='text_content' >
                                    <Box>DESCRIPTION</Box>
                                    </Grid>
                                <Grid item xs={12} className='dec_content'><Box>{this.state.data ? this.state.data.drop_off_description: ''}</Box></Grid>
                            </Grid>
                            <Grid container xs={12} >
                                <Grid item xs={12} >
                                    <CustomListItem
                                    className='panel_row'
                                    text={<Box >DRIVER INOFRMATION</Box>}
                                    open={this.state.openDriver}
                                    onClick={() => {
                                        this.setState({
                                            openDriver: !this.state.openDriver,
                                        });
                                      }}
                                    >
                                    <Grid container xs={12} className='border-bottom-white-2 panel_data'>
                                        <Grid item xs={12} className='text_content ' >
                                            <Box>NAME</Box>
                                            </Grid>
                                        <Grid item xs={12} className='dec_content ' ><Box>{this.state.data && this.state.data.drop_off_worker && this.state.data.drop_off_worker.first_name? this.state.data.drop_off_worker.first_name : ''} {this.state.data && this.state.data.drop_off_worker && this.state.data.drop_off_worker.last_name? this.state.data.drop_off_worker.last_name : ''}</Box></Grid>
                                    </Grid>
                                    <Grid container xs={12}>
                                        <Grid item xs={6}>
                                            <Grid container xs={12} className='panel_data border-right-white-2'>
                                                <Grid item xs={12} className='text_content ' >
                                                    <Box>VEHICLE NUMBER</Box>
                                                    </Grid>
                                                <Grid item xs={12} className='dec_content border-bottom-white-2' ><Box>{this.state.data && this.state.data.drop_off_worker && this.state.data.drop_off_worker.vehicle && this.state.data.drop_off_worker.vehicle.plate_no? this.state.data.drop_off_worker.vehicle.plate_no : ''} </Box></Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container xs={12} className='panel_data'>
                                                <Grid item xs={12} className='text_content ' >
                                                    <Box>CURRENT NUMBER OF JOBS</Box>
                                                    </Grid>
                                                <Grid item xs={12} className='dec_content border-bottom-white-2' ><Box>{this.state.data && this.state.data.drop_off_worker && this.state.data.drop_off_worker.in_progress_jobs? this.state.data.drop_off_worker.in_progress_jobs.total_jobs : ''} </Box></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    </CustomListItem>
                                    </Grid>
                            </Grid>
                            <Grid container xs={12}>
                                <Grid item xs={12} >
                                <CustomListItem
                                    className='panel_row'
                                    text={<Box>RECIPIENT INFORMATION</Box>}
                                    open={this.state.openRecepient}
                                    onClick={() => {
                                        this.setState({
                                            openRecepient: !this.state.openRecepient,
                                        });
                                      }}
                                    >
                                    <Grid container xs={12} className='border-bottom-white-2 panel_data'>
                                        <Grid item xs={12} className='text_content' >
                                            <Box>NAME</Box>
                                            </Grid>
                                        <Grid item xs={12} className='dec_content' ><Box>{this.state.data && this.state.data.drop_off_name ? this.state.data.drop_off_name : '-'} </Box></Grid>
                                    </Grid>
                                    <Grid container xs={12} className='border-bottom-white-2 panel_data'>
                                        <Grid item xs={12} className='text_content' >
                                            <Box>PHONE NUMBER</Box>
                                            </Grid>
                                        <Grid item xs={12} className='dec_content' ><Box>{this.state.data && this.state.data.drop_off_contact_no ? this.state.data.drop_off_contact_no  : '-'} </Box></Grid>
                                    </Grid>
                                    <Grid container xs={12} className='border-bottom-white-2 panel_data'>
                                        <Grid item xs={12} className='text_content' >
                                            <Box>EMAIL</Box>
                                            </Grid>
                                        <Grid item xs={12} className='dec_content' ><Box>{this.state.data && this.state.data.notification_email? this.state.data.notification_email: '-'} </Box></Grid>
                                    </Grid>
                                    </CustomListItem>
                                </Grid>
                            </Grid>
                            <Grid container xs={12} >
                            <Grid item xs={12} >
                                <CustomListItem
                                    className='panel_row'
                                    text={<Box>{this.state.data && this.state.data.job_type}</Box>}
                                    open={this.state.openStep}
                                    onClick={() => {
                                        this.setState({
                                            openStep: !this.state.openStep,
                                        });
                                      }}
                                    >
                                       <Grid container xs={12} className='border-bottom-white-2 panel_data'>
                                        <Grid item xs={12} className='text_content' >
                                            <Box>Address</Box>
                                            </Grid>
                                        <Grid item xs={12} className='dec_content' ><Box>{this.state.data && this.state.data.drop_off_address? this.state.data.drop_off_address: '-'} </Box></Grid>
                                    </Grid>
                                   
                                    </CustomListItem>
                                </Grid>
                            </Grid>
                            <Grid container xs={12} >
                            <Grid item xs={12} >
                                <CustomListItem
                                    className='panel_row'
                                    text={<Box>ITEMS</Box>}
                                    open={this.state.openItem}
                                    onClick={() => {
                                        this.setState({
                                            openItem: !this.state.openItem,
                                        });
                                      }}
                                      >
                                    <Grid container xs={12}>
                                        <Grid item xs={6} className='border-bottom-white-2'>
                                            <Grid container xs={12} className='panel_data border-right-white-2'>
                                                
                                                <Grid item xs={12} className='dec_content ' ><Box>TOTAL WEIGHT(KG)</Box></Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} className='border-bottom-white-2'>
                                            <Grid container xs={12} className='panel_data'>
                                                
                                                <Grid item xs={12} className='dec_content ' ><Box>TOTAL QUANTITY</Box></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12}>
                                        <Grid item xs={6}  className='border-bottom-white-2'>
                                            <Grid container xs={12} className='panel_data border-right-white-2 '>
                                                
                                                <Grid item xs={12} className='dec_content ' ><Box>{this.state.data && this.state.data.order_details.length > 0 ? parseInt(this.state.data.order_details[0].weight)  : 0} </Box></Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} className='border-bottom-white-2'>
                                            <Grid container xs={12} className='panel_data'>
                                                
                                                <Grid item xs={12} className='dec_content border-bottom-white-2' ><Box>{this.state.data && this.state.data.order_details.length > 0? parseInt(this.state.data.order_details[0].quantity): 0} </Box></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    </CustomListItem>
                                </Grid>
                            </Grid>
                        </Box>
                        
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Box >
                        <Grid item xs={12}>
                            <CustomGoogleMap 
                            center={(this.state.data && this.state.data.drop_off_worker && this.state.data.drop_off_worker.worker_location_histories && this.state.data.drop_off_worker.worker_location_histories[0].latitude !== '' && this.state.data.drop_off_worker.worker_location_histories[0].longitude !== '') ? { lat: this.state.data.drop_off_worker.worker_location_histories[0].latitude, lng: this.state.data.drop_off_worker.worker_location_histories[0].longitude } : null}
                            driver= {(this.state.data && this.state.data.drop_off_worker && this.state.data.drop_off_worker_name) ?
                                this.state.data.drop_off_worker_name 
                                // +" - "+
                                // this.state.data.drop_off_worker.vehicle && this.state.data.drop_off_worker.vehicle.plate_no
                                
                             : ''}
                            //  step_address = {this.state.data && this.state.data.job_steps.length > 0? this.state.data.job_steps : ''}
                            />
                        </Grid>
                        
                    </Box>
                </Grid>
            </Grid>
        </Box>
    }
    
    render() {
    if (this.state.isLoading) {
        return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
          <CircularProgress size={35} />
        </Box>
      } else {
          return (
            <Box>

                {this.state.error ? this.errorMessage() : this.trackOrder()}
            </Box>
        )
      }
        
    }
}

export default trackMe
